<template>
  <footer
    class="p-6 bg-main-purple-500 sm:p-6 md:pt-10 bottom-0 left-0 right-0 w-full rounded-br-3xl"
  >
    <div class="mx-auto max-w-screen-xl">
      <div class="grid grid-cols-1 md:grid-cols-7 gap-10">
        <div class="md:col-span-2">
          <div class="mb-6 md:mb-0">
            <a href="#" class="flex items-center">
              <img
                src="@/assets/img/logo_nova_bco.svg"
                class="mx-auto md:mx-0 md:mr-3 h-12 md:h-16"
                alt="Nova Logo"
              />
            </a>
            <h2 class="my-6 text-lg font-black text-main-yellow-500 uppercase">
              Sua conexão começa aqui!
            </h2>
          </div>    
          <div class="grid grid-cols-1 md:grid-cols-4 gap-1 mx-auto">
        <div>
          <a href="https://www.reclameaqui.com.br/empresa/nova-internet-banda-larga/" class="flex items-center" target="_blank">
            <img
              src="@/assets/img/selo_ra1000.png"
              class="mx-auto w-full"
              alt="Selo Reclame Aqui 1000"
            />
          </a>
        </div>
        <div><a href='https://melhorplano.net/internet-banda-larga/to/palmas#premio' title='Vencedor Melhor Velocidade Palmas 2025'><img src='https://cdn.melhorplano.net/cms/2025/01/24/67939ed8698ddselo-melhor-velocidade-municipal-2025.svg' alt='Vencedor Melhor Velocidade Palmas 2025' class="w-full"></a></div>
        <div><a href='https://melhorplano.net/internet-banda-larga/sc/cunha-pora#premio' title='Vencedor Maior Estabilidade Cunha Pora 2025'><img src='https://cdn.melhorplano.net/cms/2025/01/24/67939dde1e94dselo-maior-estabilidade-municipal-2025.svg' alt='Vencedor Maior Estabilidade Cunha Pora 2025' class="w-full"></a></div>
        <div><a href='https://melhorplano.net/internet-banda-larga/to/porto-nacional#premio' title='Vencedor Melhor Internet Gamer Porto Nacional 2025'><img src='https://cdn.melhorplano.net/cms/2025/01/24/67939e201bef9selo-melhor-internet-gamer-municipal-2025.svg' alt='Vencedor Melhor Internet Gamer Porto Nacional 2025' class="w-full"></a></div>
      </div>      
        </div>
        <div class="md:col-span-5">

          <div>
    
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <ul class="text-white text-lg">
                  <li class="mb-4">
                    <router-link
                      to="/sobre-nos"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Sobre nós</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/nossas-lojas"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Nossas lojas</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/fale-conosco"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Fale conosco</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/trabalhe-conosco"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Trabalhe conosco</router-link
                    >
                  </li>
                </ul>
              </div>
              <div>
                <ul class="text-white text-lg">
                  <li class="mb-4">
                    <router-link
                      to="/negociacao"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Negociação</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/planos"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Planos</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/upgrade"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Upgrade</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/segunda-via"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Segunda via</router-link
                    >
                  </li>
                </ul>
              </div>
              <div>
                <ul class="text-white text-lg">
                  <li class="mb-4">
                    <router-link
                      to="/faq"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Perguntas frequentes</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/contratos"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Contratos e Regulamentos</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/lgpd"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >LGPD</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/ouvidoria"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Ouvidoria</router-link
                    >
                  </li>
                  <li class="mb-4">
                    <router-link
                      to="/nova-mais"
                      class="hover:font-bold hover:text-main-yellow-300"
                      >Nova Mais</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <hr class="my-6 border-main-pink-200 sm:mx-auto lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-white sm:text-center"
          >© 2023
          <a href="https://rnova.com.br" class="hover:underline">Nova Telecom</a
          >. Todos os direitos reservados.
        </span>
        <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a
            href="https://www.facebook.com/NovaTelecomInternet/"
            target="_blank"
            class="text-main-yellow-500 hover:text-main-yellow-50 text-xl"
          >
            <BIconFacebook /> 
          </a>
          <a
            href="https://www.instagram.com/novatelecominternet/"
            target="_blank"
            class="text-main-yellow-500 hover:text-main-yellow-50 text-xl"
          >
            <BIconInstagram />
          </a>
          <a
            href="https://www.youtube.com/@novatelecom1053"
            target="_blank"
            class="text-main-yellow-500 hover:text-main-yellow-50 text-xl"
          >
            <BIconYoutube />
          </a>
          <a
            href="https://www.linkedin.com/company/novatelecominternet/"
            target="_blank"
            class="text-main-yellow-500 hover:text-main-yellow-50 text-xl"
          >
            <BIconLinkedin />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSite",
};
</script>

<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            A linha da nossa história
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            A <strong class="font-bold">Nova</strong> conecta, há mais de 19
            anos, milhares de famílias nas regiões metropolitanas e interior de
            Minas Gerais.
          </h3>
        </div>
        <div class="flex flex-col justify-center divide-y [&>*]:py-8">
          <div class="w-full max-w-4xl mx-auto">
            <!-- Vertical Timeline #1 -->
            <div class="-my-6">
              <timeline-about
                date-label="2005-2015"
                title-timeline="Início da Jornada"
              >
                A Nova Telecom nasceu na cidade de Ribeirão das Neves- MG,
                região metropolitana de Minas Gerais se tornando líder de
                mercado na região de atuação, devido a tecnologia de ponta e
                investimento nos profissionais locais.
              </timeline-about>
              <timeline-about
                date-label="2015"
                title-timeline="Fusão com R7 Telecom"
              >
                Com o Crescimento contínuo na sua região de atuação, onde chegou
                expandir seus serviços nas cidades de Santa Luzia e parte da
                Cidade de Esmeraldas- Minas Gerais, a Nova iniciou a fusão com o
                provedor R7 Telecom, gerando mais capacidade de cobertura de sua
                rede. Desde então, a empresa se tornou referência de qualidade e
                prestação de serviços no mercado de Telecom.
              </timeline-about>
              <timeline-about
                date-label="2016-2020"
                title-timeline="Expansão da base"
              >
                Com novas áreas de atuação e investimentos de novos
                equipamentos, a empresa aumentou a sua base em um ano após sua
                fusão em 198%.
              </timeline-about>
              <timeline-about
                date-label="2020-2021"
                title-timeline="Referência no mercado"
              >
                Com crescimento de base anual, a empresa foi capa da 22ª edição
                da revista ISP Mais como provedor destaque e trajetória de
                referência no mercado de Telecom.
              </timeline-about>
              <timeline-about
                date-label="2021-2022"
                title-timeline="Investimento do Light Wave Capital"
              >
                <p>
                  No ano de 2021 a empresa obteve investimento do fundo Light
                  Wave Capital que somou
                  <strong
                    >o sonho de abraçar mais cidades no interior do país e
                    regiões metropolitanas</strong
                  >. O Fundo é especialista em desenvolvimento de provedores em
                  Telecomunicações e chegou ao mercado brasileiro, com a missão
                  de evoluir ainda mais as empresas em que possuem parcerias.
                </p>
                <br />
                <p>
                  Com uma jornada de sucesso, a Nova buscou novas parcerias,
                  para um mercado competitivo e desafiador.
                </p>
              </timeline-about>
            </div>
          </div>
        </div>
        <div class="w-full max-w-4xl mx-auto text-center">
          <h3 class="font-regular text-main-pink-500 text-2xl mb-8">
            Com investimentos e crescimento contínuo, bem como a preservação do
            atendimento local, com uma gestão eficiente e transparente e alta
            eficiência em sua gestão, sem jamais esquecer das suas raízes, a
            Nova tem como meta,
            <strong class="font-extrabold"
              >levar o mundo da internet para milhares de lares e famílias
              desatendidas pelo Brasil.</strong
            >
          </h3>
          <h1
            class="font-regular text-main-yellow-500 text-4xl tracking-tight bg-main-purple-500 px-8 py-6 rounded-3xl leading-9"
          >
            O nosso sonho de incluir milhares de pessoas ao mundo da internet é
            <strong class="font-black"
              >O QUE NOS DESAFIA E NOS MOTIVA TODOS OS DIAS!</strong
            >
          </h1>
          <youtube-embed embed-link="n96FBOmhN0o" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TimelineAbout from "@/components/layout/about/TimelineAbout.vue";
import YoutubeEmbed from "@/components/layout/goods/YoutubeEmbed.vue";
export default {
  name: "App",
  components: {
    TimelineAbout,
    YoutubeEmbed,
  },
};
</script>
